//
// Labels
//

// For use with horizontal and inline forms, when you need the label (or legend)
// text to align with the form controls.
.col-form-label,
.col-form-label-lg,
.col-form-label-sm {
  @include font-size($form-label-font-size); // Override the `<legend>` default
  align-self: center;
}
